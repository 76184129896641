.slick-dots li.slick-active button:before {
    color: #306CEF!important;
}

.slick-slide {
    transition: none !important;
}
  .my-unique-slider .slick-current .my-slider-slide {
    transform: scale(1.1);
    transition: all 3s ease;

  }
  